@import "../../Styles.scss";

.video {
    position: relative;
    width: 100%;

    & video {
        width: 100%;
        height: auto;
        max-height: 60vh;
    }

    &-button {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: $SPACING_XL;
        transition: 0.5s;

        &--playing {
            opacity: 0;
        }
    }
}

#video:hover + .video-button--playing,
.video-button--playing:hover {
    opacity: 1;
}