@import "../Styles.scss";

.nieuwsbericht {
    padding-bottom: $SPACING_4XL;
    &-container {
        width: 100%;
        max-width: 800px;
        margin: auto;
        padding: $SPACING_M 0;

        @media (max-width: $SCREEN_SIZE_S) {
            padding: $SPACING_S 0;
        }

        &--banner {
            @extend .nieuwsbericht-container;
            padding: 0 0 $SPACING_M 0;

            @media (max-width: $SCREEN_SIZE_S) {
                padding: 0;
            }
        }
    }

    &-image-container {
        position: relative;
        object-fit: cover;
        width: 100%;

        &::after {
            content: "";
            display: block;
            padding-bottom: 60%;
        }

        & img {
            object-fit: cover;
            position: absolute;
            inset: 0px;
            width: 100%;
            height: 100%;
        }
    }

    &-header {
        &-date {
            font-size: $TEXT_S;
            font-weight: $REGULAR;
            color: $GREY;
            margin: 0;
        }
        &-title {
            font-size: $TEXT_3XL;
            font-weight: $BOLD;
            color: $BLACK;
            margin: 0;

            @media (max-width: $SCREEN_SIZE_S) {
                font-size: $TEXT_XXL;
            }
        }
    }

    & ul {
        list-style-position: inside;
        & p {
            display: inline-block;
        }
    }
    &-alinea-container {
        padding: $SPACING_S 0;
        list-style-position: inside;
        & p {
            display: inline-block;
            max-width: 90%;
            vertical-align: top;
        }


        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
        }
    }

    &-image-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: $SPACING_S;
        width: 100%;

        @media (max-width: $SCREEN_SIZE_M) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (max-width: $SCREEN_SIZE_S) {
            grid-template-columns: repeat(2, 1fr);
        }

        &-item {
            position: relative;
            object-fit: cover;
            width: 100%;

            &::after {
                content: "";
                display: block;
                padding-bottom: 100%;
            }

            & img {
                object-fit: cover;
                position: absolute;
                inset: 0px;
                width: 100%;
                height: 100%;
            }
        }
    }

    &-socials {
        margin: $SPACING_3XL 0;
        &-titel {
            font-size: $TEXT_XXL;
            font-weight: $MEDIUM;
            margin-bottom: $SPACING_S;
        }

        &-items {
            display: flex;
            gap: $SPACING_L;
        }

        &-item {
            display: flex;
            align-items: center;
            gap: $SPACING_S;
            margin: $SPACING_3XS 0;

            &-logo {
                height: 25px;
                width: 25px;
            }
            &-link {
                color: $BLACK;
                text-decoration: none;
            }
        }
    }

    &-more {
        &-title {
            font-size: $TEXT_3XL;
            font-weight: $BOLD;
            color: $BLACK;
        }
        &-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: $SPACING_L;
            margin: 0 0 $SPACING_M;

            @media(max-width: $SCREEN_SIZE_M) {
                grid-template-columns: 1fr;
            }
        }
        &-item {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: $SPACING_M;
            margin: $SPACING_M 0;

            &-image {
                cursor: pointer;
                position: relative;
                object-fit: cover;
                width: 100%;

                &::after {
                    content: "";
                    display: block;
                    padding-bottom: 75%;
                }
                & img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: 50% 50%;
                }
            }
            &-inhoud {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                padding: $SPACING_XXS 0;
            }
            &-title {
                font-size: $TEXT_XXL;
                text-decoration: none;
                font-weight: $BOLD;
                color: $BLACK;
                word-break: break-word;
                cursor: pointer;
            }
            &-datum {
                font-weight: $LIGHT;
                font-size: $TEXT_S;
            }
            &-text {
                margin-top: $SPACING_M;
                font-size: $TEXT_M;
                font-weight: $MEDIUM;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
            }
            &-link {
                margin-top: $SPACING_XS;
                text-decoration: underline;
                font-size: $TEXT_M;
                font-weight: $MEDIUM;
                color: $BLACK;
            }
        }

        @media (max-width: $SCREEN_SIZE_M) {
            &-title {
                margin-bottom: $SPACING_M;
                font-size: $TEXT_XXL;
            }
            &-item {
                flex-direction: row;
                margin: 0;

                &-image {
                    & img {
                        position: relative;
                        width: 170px;
                        height: 170px;
                    }
                    &::after {
                        padding-bottom: 0;
                    }
                }
                &-inhoud {
                    padding: 0;
                }
                &-title {
                    font-size: $TEXT_XL;
                }
                &-datum {
                    font-size: $TEXT_XS;
                }
            }
        }
        @media (max-width: $SCREEN_SIZE_S) {
            &-item {
                &-image {
                    width: unset;
                    & img {
                        width: 100px;
                        height: 100px;
                    }
                }
                &-text {
                    display: none;
                }
                &-link {
                    display: none;
                }
            }
        }
    }
}
