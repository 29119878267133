@import "../../Styles.scss";

.input {
    position: relative;
    display: flex;
    align-items: center;
    border: none;
    background-color: $LIGHT_GREY;
    border-radius: $SPACING_L;
    padding: $SPACING_3XS;
    width: 100%;
    transition: 0.2s;

    @media (max-width: $SCREEN_SIZE_S) {
        order: 4;
        width: 100%;
        margin-left: 0;
    }

    &--success {
        color: $LIGHT_GREY;
        animation: fill .4s ease-in-out forwards;

        & input {
            color: $LIGHT_GREY;
        }
        & input::placeholder {
            color: $LIGHT_GREY;
        }
    }

    & input {
        height: 100%;
        width: 100%;
        padding: $SPACING_XS;
        border: none;
        background-color: transparent;
        outline: none;
        font-size: $TEXT_L;
    }

    &-icon {
        padding: 0 $SPACING_XS;
        height: 1.5rem;
        width: 1.5rem;
        cursor: pointer;
    }

    &-checkmark {
        width: 34.5px;
        height: 34.5px;
        border-radius: 50%;
        display: block;
        stroke-width: 2;
        stroke: $LIGHT_GREY;
        stroke-miterlimit: 10;
        margin: 0 auto;

        &-check {
            transform-origin: 50% 50%;
            stroke-dasharray: 48;
            stroke-dashoffset: 48;
            animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.6s forwards;
        }
    }
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px $GREEN;
  }
}
