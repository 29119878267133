@import "../Styles.scss";

.vacatures {
    &-banner {
        display: grid;
        grid-template-columns: 8fr 4fr;
        width: 100%;
        height: 100%;
        background-color: $DARK_GREY;

        @media(max-width: $SCREEN_SIZE_L) {
            grid-template-columns: 1fr 1fr;
        }
        @media(max-width: $SCREEN_SIZE_M) {
            grid-template-columns: unset;
        }

        &-image {
            position: relative;
            width: 100%;
            height: auto;
            display: block;
            padding-bottom: 75%;

            @media (min-width: $SCREEN_SIZE_M) and (max-width: $SCREEN_SIZE_L) {
                padding-bottom: 100%;
            }

            @keyframes bannerFadeIn {
                0% {
                    opacity: 0;
                    visibility: hidden;
                }
                100% {
                    opacity: 1;
                    visibility: visible;
                }
            }
            @keyframes bannerFadeOut {
                0% {
                    opacity: 1;
                    visibility: visible;
                }
                100% {
                    opacity: 0;
                    visibility: hidden;
                }
            }

            & img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                top: 0;
                left: 0;
                opacity: 1;
                visibility: visible;
            }

            &--open {
                animation: 0.5s bannerFadeIn linear forwards;
            }
            &--close {
                animation: 0.5s bannerFadeOut linear forwards;
            }
        }

        &-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: $SPACING_L $SPACING_L;

            &-text {
                &-title {
                    color: $YELLOW;
                    font-size: $TEXT_3XL;
                }
                &-text {
                    color: $LIGHT_GREY;
                    margin-top: $SPACING_L;
                    font-size: $TEXT_L;

                    &--bold {
                        font-weight: $BOLD;
                    }
                }
            }

            &-footer {
                display: flex;
                align-items: center;

                @media(max-width: $SCREEN_SIZE_M) {
                    margin-top: $SPACING_L;
                }

                &-button {
                    margin-right: $SPACING_L;
                }
                &-link {
                    color: $YELLOW;
                    text-underline-offset: 2px;
                    font-size: $TEXT_L;
                }
            }
        }
    }

    &-filter-container {
        margin: $SPACING_4XL 0 $SPACING_XL;
    }

    &-filters {
        display: flex;
        flex-wrap: wrap;
        padding: $SPACING_M 0;
        border-bottom: 1px solid $LIGHT_GREY;

        & > * {
            margin: $SPACING_S $SPACING_S;

            &:nth-child(1) {
                margin: $SPACING_S $SPACING_S $SPACING_S 0;
            }
        }

        &-input {
            position: relative;
            display: flex;
            align-items: center;
            border: none;
            background-color: $LIGHT_GREY;
            border-radius: $SPACING_L;
            padding: $SPACING_XXS;

            @media (max-width: $SCREEN_SIZE_S) {
                order: 4;
                width: 100%;
                margin-left: 0;
            }

            & input {
                height: 100%;
                width: 100%;
                padding: $SPACING_XS;
                border: none;
                background-color: transparent;
                outline: none;
                font-size: $TEXT_L;
            }

            &--icon {
                padding: 0 $SPACING_XS;
                height: 1.5rem;
                width: 1.5rem;
                cursor: pointer;
            }
        }

        &-zoekterm {
            color: $DARK_GREY;
            font-size: $TEXT_XL;
            font-weight: $MEDIUM;
            margin-top: $SPACING_S;
        }
    }

    &-overzicht {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        row-gap: $SPACING_L;
        column-gap: $SPACING_L;
        margin: $SPACING_XL 0;

        @media (max-width: $SCREEN_SIZE_L) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: $SCREEN_SIZE_S) {
            grid-template-columns: 1fr;
        }
    }

    &-card {
        background-color: $DARK_GREY;
        color: $LIGHT_GREY;
        padding: $SPACING_M;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &--new {
            border: 1px dashed $GREY;
            padding: $SPACING_5XL $SPACING_M;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            text-decoration: none;
        }

        &-title {
            color: $YELLOW;
            margin-bottom: $SPACING_M;
            font-size: $TEXT_3XL;
        }

        &-list {
            list-style-position: inside;
            margin: $SPACING_M 0;

            &-item {
                display: flex;
                margin: $SPACING_S 0;

                & svg {
                    height: 1.3rem;
                    width: 1.3rem;
                }
                & path {
                    stroke: $YELLOW;
                    fill: $YELLOW;
                }
                & p {
                    margin-left: $SPACING_M;
                }
            }
        }

        &-beschrijving {
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
        }

        &-footer {
            margin-top: $SPACING_L;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            &-datum {
                flex-grow: 1;
                width: 100%;
                margin-bottom: $SPACING_S;
            }
        }


        &-icon {
            font-size: $TEXT_5XL;
            color: $GREY;
        }

        &-text {
            font-size: $TEXT_XL;
            font-weight: $LIGHT;
            color: $GREY;
            margin-top: $SPACING_M;
        }
    }

    &-geen-resultaten {
        text-align: center;
        font-size: $TEXT_3XL;
    }
}

.no-animation {
    & img {
        animation-duration: 0s !important;
    }
}
