@import "../../Styles.scss";

.navbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: $SPACING_3XS 0;
    border-bottom: 1px solid $LIGHT_GREY;


    &-section {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        margin-right: $SPACING_4XL;
        padding: $SPACING_S 0;

        @media (max-width: $SCREEN_SIZE_S) {
            width: 100%;
            justify-content: space-between;
            margin: 0;
        }

        &-logo {
            height: 2.5rem;

            @media (max-width: $SCREEN_SIZE_M) {
                height: 2rem;
            }
        }
    }


    &-links {
        margin-left: $SPACING_S;
        @media (max-width: $SCREEN_SIZE_S) {
            display: none;
        }

        a {
            display: inline-block;
            margin: 0 1rem;
            text-decoration: none;
            color: #020202;
            font-size: $TEXT_L;
        }
    }

    &-hamburger-menu {
        @media (min-width: $SCREEN_SIZE_S) {
            display: none;
        }
    }

    &-hamburger-icon {
        height: 1.5rem;
        width: 1.5rem;
        cursor: pointer;
    }

    &-input {
        flex-grow: 1;
        position: relative;
        display: flex;
        align-items: center;
        border: none;
        background-color: $LIGHT_GREY;
        border-radius: $SPACING_L;
        padding: $SPACING_XXS;
        margin: $SPACING_S 0;

        & input {
            flex-grow: 1;
            height: 100%;
            width: 100%;
            padding: $SPACING_XS;
            border: none;
            background-color: transparent;
            outline: none;
            font-size: $TEXT_L;
        }

        &--icon {
            padding: 0 $SPACING_XS;
            height: 1.5rem;
            width: 1.5rem;
            cursor: pointer;
        }
    }
}

#vacatures-link {
    font-weight: 700;
    margin-left: 0;
}

.sidebar {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $DARK_GREY;
    transform: translateX(-100%);
    transition: 0.5s;
    z-index: 1000;

    &-open {
        transform: translateX(0%);
    }

    @media (min-width: $SCREEN_SIZE_S) {
        display: none;
    }

    h1 {
        color: $LIGHT_GREY;
    }

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $SPACING_L $SPACING_M 0 $SPACING_M;
    }

    &-header-icon {
        color: $LIGHT_GREY;
        height: 2.5rem;
        width: 2.5rem;
    }

    &-input {
        flex-grow: 1;
        position: relative;
        display: flex;
        align-items: center;
        border: none;
        background-color: $LIGHT_GREY;
        border-radius: $SPACING_L;
        padding: $SPACING_XXS;
        margin: $SPACING_L $SPACING_M;

        & input {
            flex-grow: 1;
            height: 100%;
            width: 100%;
            padding: $SPACING_S;
            border: none;
            background-color: transparent;
            outline: none;
            font-size: $TEXT_L;
        }

        &--icon {
            padding: 0 $SPACING_XS;
            height: 1.5rem;
            width: 1.5rem;
            cursor: pointer;
        }
    }

    &-links {
        display: flex;
        flex-direction: column;
        padding: 0 $SPACING_L;
        margin-top: $SPACING_L;

        a {
            margin: $SPACING_S 0;
            display: inline-block;
            text-decoration: none;
            color: $LIGHT_GREY;
            font-size: $TEXT_L;
        }
    }
}
.nav-route {
    margin: $SPACING_XXS 0;

    & a {
        color: $DARK_GREY;
        text-decoration: none;
    }
}
