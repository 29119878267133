@import "../../Styles.scss";

.footer {
    background-color: $DARK_GREY;
    padding: $SPACING_XL $SPACING_L;

    &-content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        color: $LIGHT_GREY;

        @media (max-width: $SCREEN_SIZE_M) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
        }
        @media (max-width: $SCREEN_SIZE_S) {
            grid-template-columns: repeat(1, 1fr);
            padding: $SPACING_XL $SPACING_M;
        }

        & > div {
            width: auto;
            padding: $SPACING_M 0;
            margin: 0 auto;

            @media (max-width: $SCREEN_SIZE_XS) {
                margin: 0;
            }
        }
    }



    &-title {
        margin-bottom: $SPACING_M;
        font-size: $TEXT_3XL;
    }

    &-lists {
        display: flex;
    }

    &-list {
        list-style-type: none;

        & > li {
            line-height: 2rem;
            font-size: $TEXT_L;
        }

        &-link {
            color: $LIGHT_GREY;
            text-decoration: none;
            cursor: pointer;
        }

        &--menu {
            list-style-type: none;
            line-height: 2rem;
            margin-right: $SPACING_3XL;
        }

        &-item {
            &--contact {
                display: flex;
                align-items: center;

                & > a {
                    margin-left: $SPACING_S;
                    color: $LIGHT_GREY;
                    text-decoration: underline;
                }
            }

            &-icon {
                height: 1.6rem;
                width: 1.6rem;
            }

            &--vacatures {
                font-weight: 700;
            }
        }
    }

    &-logos {
        // max-width: 150px;
        &-flex {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
    }

    &-image {
        margin-bottom: $SPACING_M;
        & img {
            width: 100%;
            width: 150px;
        }
    }

    &-made-by {
        border-top: 1px solid $GREY;
        padding: $SPACING_XS $SPACING_M;
        color: $LIGHT_GREY;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: $SPACING_S;

        @media (max-width: $SCREEN_SIZE_S) {
            flex-direction: column;
        }

        & a {
            color: $LIGHT_GREY;
            font-weight: $LIGHT;
        }
    }
}
