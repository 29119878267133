@import "../../Styles.scss";

.button {
    display: inline-flex;
    gap: $SPACING_S;
    align-items: center;
    padding: $SPACING_M;
    border-radius: $SPACING_XXL;
    border: none;
    cursor: pointer;
    font-size: $TEXT_L;

    &-lightGrey {
        background-color: $LIGHT_GREY;
        color: $DARK_GREY;
    }
    &-darkGrey {
        background-color: $DARK_GREY;
        color: $LIGHT_GREY;
    }
    &-yellow {
        background-color: $YELLOW;
        color: $DARK_GREY;
    }
    &-green {
        cursor: auto;
        animation: fill .4s ease-in-out forwards;
    }

    &-icon {
        height: 1.5rem;
        width: 1.5rem;
    }
    &-checkmark {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        display: block;
        stroke-width: 3;
        stroke: $LIGHT_GREY;
        stroke-miterlimit: 10;
        margin: 0 auto;

        &-check {
            transform-origin: 50% 50%;
            stroke-dasharray: 48;
            stroke-dashoffset: 48;
            animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.6s forwards;
        }
    }
}
@keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
@keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px $GREEN;
    }
  }
