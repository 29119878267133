@import "../Styles.scss";

.home {
    &-banner {
        display: grid;
        grid-template-columns: 8fr 4fr;
        width: 100%;
        height: 100%;
        background-color: $DARK_GREY;

        @media(max-width: $SCREEN_SIZE_L) {
            grid-template-columns: 1fr 1fr;
        }
        @media(max-width: $SCREEN_SIZE_M) {
            grid-template-columns: unset;
        }

        &-image {
            position: relative;
            width: 100%;
            height: auto;
            display: block;
            padding-bottom: 75%;

            @media (min-width: $SCREEN_SIZE_M) and (max-width: $SCREEN_SIZE_L) {
                padding-bottom: 100%;
            }

            & img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                top: 0;
                left: 0;
            }
        }

        &-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: $SPACING_L $SPACING_L;

            &-text {
                &-title {
                    color: $YELLOW;
                    font-size: $TEXT_3XL;
                }
                &-text {
                    color: $LIGHT_GREY;
                    margin-top: $SPACING_L;
                    font-size: $TEXT_M;

                    &--bold {
                        font-weight: $BOLD;
                    }
                }
                & ul {
                    list-style-position: inside;
                    & p {
                        display: inline-block;
                    }
                }
            }

            &-footer {
                display: flex;
                align-items: center;

                @media(max-width: $SCREEN_SIZE_M) {
                    margin-top: $SPACING_L;
                }

                &-button {
                    margin-right: $SPACING_L;
                }
                &-link {
                    color: $YELLOW;
                    text-underline-offset: 2px;
                    font-size: $TEXT_L;
                }
            }
        }
    }

    &-line {
        width: 100%;
        height: 2px;
        background-color: $LIGHT_GREY;
        margin: $SPACING_3XL 0;
    }

    &-services {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: $SPACING_XL;
        justify-items: center;

        @media (max-width: $SCREEN_SIZE_M) {
            column-gap: $SPACING_XL;
        }

        @media (max-width: $SCREEN_SIZE_S) {
            grid-template-columns: unset;
            grid-template-rows: repeat(2, 1fr);
            row-gap: $SPACING_XL;
        }

        &-card {
            background-color: $DARK_GREY;
            margin-left: $SPACING_4XL;
            max-width: 500px;
            display: flex;
            flex-direction: column;

            @media (max-width: $SCREEN_SIZE_M) {
                margin-left: $SPACING_XL;
            }

            @media (max-width: $SCREEN_SIZE_S) {
                margin-left: 0;
            }

            &:nth-child(2) {
                background-color: $LIGHT_GREY;
                margin-left: 0;
                margin-right: $SPACING_4XL;

                @media (max-width: $SCREEN_SIZE_M) {
                    margin-right: $SPACING_XL;
                }

                @media (max-width: $SCREEN_SIZE_S) {
                    margin-right: 0;
                }
            }

            &-image {
                position: relative;
                width: 100%;
                height: auto;
                display: block;
                padding-bottom: 75%;

                @media (min-width: $SCREEN_SIZE_M) and (max-width: $SCREEN_SIZE_L) {
                    padding-bottom: 100%;
                }

                & img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    top: 0;
                    left: 0;
                }
            }

            &-content {
                flex-grow: 1;
                padding: $SPACING_L;
                color: $LIGHT_GREY;
                min-height: 300px;
                display: flex;
                flex-direction: column;

                &-title {
                    color: $YELLOW;
                    font-size: $TEXT_3XL;
                    margin-bottom: $SPACING_M;

                    &--dark {
                        color: $DARK_GREY;
                        font-size: $TEXT_3XL;
                    }
                }
                &-text {
                    margin-bottom: $SPACING_M;
                    font-size: $TEXT_L;

                    &--dark {
                        margin-top: $SPACING_M;
                        color: $DARK_GREY;
                        font-size: $TEXT_L;
                    }
                }

            }

            &-footer {
                display: flex;
                align-items: center;
                margin-top: auto;

                &-button {
                    margin-right: $SPACING_L;
                }

                &-link {
                    color: $YELLOW;
                    font-size: $TEXT_L;

                    &--dark {
                        color: $DARK_GREY;
                        font-size: $TEXT_L;
                    }
                }
            }
        }
    }

    &-news {
        &-title {
            font-size: $TEXT_3XL;
        }
        &-grid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: $SPACING_L;
            margin: 0 0 $SPACING_L 0;

            @media(max-width: $SCREEN_SIZE_M) {
                grid-template-columns: 1fr;
            }
        }
        &-item {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: $SPACING_M;
            margin: $SPACING_M 0;

            &-image {
                cursor: pointer;
                position: relative;
                object-fit: cover;
                width: 100%;

                &::after {
                    content: "";
                    display: block;
                    padding-bottom: 75%;
                }
                & img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: 50% 50%;
                }
            }
            &-inhoud {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                padding: $SPACING_XXS 0;
            }
            &-title {
                font-size: $TEXT_XXL;
                text-decoration: none;
                font-weight: $BOLD;
                color: $BLACK;
                word-break: break-word;
                cursor: pointer;
            }
            &-datum {
                font-weight: $LIGHT;
                font-size: $TEXT_S;
            }
            &-text {
                margin-top: $SPACING_M;
                font-size: $TEXT_M;
                font-weight: $MEDIUM;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
            }
            &-link {
                margin-top: $SPACING_XS;
                text-decoration: underline;
                font-size: $TEXT_M;
                font-weight: $MEDIUM;
                color: $BLACK;
            }
        }

        @media (max-width: $SCREEN_SIZE_M) {
            &-item {
                flex-direction: row;
                margin: 0;

                &-image {
                    & img {
                        position: relative;
                        width: 170px;
                        height: 170px;
                    }
                    &::after {
                        padding-bottom: 0;
                    }
                }
                &-inhoud {
                    padding: 0;
                }
                &-title {
                    font-size: $TEXT_XL;
                }
                &-datum {
                    font-size: $TEXT_XS;
                }
            }
        }
        @media (max-width: $SCREEN_SIZE_S) {
            &-item {
                &-image {
                    width: unset;
                    & img {
                        width: 100px;
                        height: 100px;
                    }
                }
                &-text {
                    display: none;
                }
                &-link {
                    display: none;
                }
            }
        }
    }
}
