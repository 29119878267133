@import "./styles/Styles.scss";

.App {
  display: flex;
  flex-direction: column;

  &-content {
    width: calc(100% - $SPACING_XS*2);
    max-width: $SCREEN_SIZE_XL;
    padding: 0 $SPACING_XS;
    min-height: 100vh;

    @media (min-width: $SCREEN_SIZE_M) {
      width: calc(100% - $SPACING_XL*2);
      padding: 0 $SPACING_XL;
      margin: 0 auto;
    }

    > div:nth-of-type(3) {
      // Item under navbar
      padding-top: 2rem;

      @media(max-width: $SCREEN_SIZE_XL) {
        padding-bottom: 2rem;
      }
    }
  }
}
