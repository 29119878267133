// Screen sizes
$SCREEN_SIZE_XS: 400px;
$SCREEN_SIZE_S: 768px;
$SCREEN_SIZE_M: 992px;
$SCREEN_SIZE_L: 1200px;
$SCREEN_SIZE_XL: 1400px;

// Font sizes
$TEXT_XXS: 0.5rem;
$TEXT_XS: 0.625rem;
$TEXT_S: 0.8125rem;
$TEXT_M: 0.9375rem;
$TEXT_L: 1rem;
$TEXT_XL: 1.25rem;
$TEXT_XXL: 1.5rem;
$TEXT_3XL: 1.875rem;
$TEXT_4XL: 2.125rem;
$TEXT_5XL: 3.75rem;

// Border Radius Sizes
$BORDER_S: 0.375rem;
$BORDER_M: 0.625rem;
$BORDER_L: 1rem;
$BORDER_XL: 2rem;