@import "../Styles.scss";

.vacature {

    &-overzicht {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        @media (max-width: $SCREEN_SIZE_M) {
            grid-template-columns: 1fr;
        }

        & > div:nth-child(1) {
            padding-right: $SPACING_4XL;
            border-right: 1px solid $LIGHT_GREY;

            @media (max-width: $SCREEN_SIZE_L) {
                padding-right: $SPACING_XL;
            }
            @media (max-width: $SCREEN_SIZE_M) {
                padding-right: 0;
                border-right: none;
            }
        }
        & > div:nth-child(2) {
            padding-left: $SPACING_4XL;
            border-left: 1px solid $LIGHT_GREY;

            @media (max-width: $SCREEN_SIZE_L) {
                padding-left: $SPACING_XL;
            }
            @media (max-width: $SCREEN_SIZE_M) {
                padding-left: 0;
                border-left: none;
            }
        }
    }

    &-info {
        &-content {
            background-color: $DARK_GREY;
            padding: $SPACING_L;
            color: $LIGHT_GREY;
            // word-break: break-all;
        }

        &-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: $SPACING_M;
        }

        &-menu {
            display: flex;
            margin-bottom: $SPACING_M;
            gap: $SPACING_L;

            & p {
                cursor: pointer;
            }

            &-active {
                color: $YELLOW;
            }
        }

        &-input {
            border: none;
            outline: none;
            background-color: $LIGHT_GREY;
            padding: $SPACING_M;
            font-size: $TEXT_L;
            width: calc(100% - $SPACING_M * 2);

            &--select {
                border: none;
                outline: none;
                background-color: $LIGHT_GREY;
                padding: $SPACING_M;
                font-size: $TEXT_L;
                width: 100%;
            }

            &--textarea {
                border: none;
                outline: none;
                background-color: $LIGHT_GREY;
                padding: $SPACING_S;
                font-size: $TEXT_L;
                font-family: inherit;
                width: calc(100% - $SPACING_S * 2);
                height: 125px;
                resize: none;
            }

            &-title {
                margin-bottom: $SPACING_XXS;
            }

            &-text {
                font-size: $TEXT_L;
                margin-bottom: $SPACING_XXS;
            }

            &-container {
                display: flex;
                background-color: $LIGHT_GREY;
                color: $DARK_GREY;
                align-items: center;
                width: 100%;
            }
        }

        &-icon {
            font-size: $TEXT_XXL;
            cursor: pointer;

            &--input {
                font-size: $TEXT_XXL;
                cursor: pointer;
                padding-right: $SPACING_S;
            }
        }

        &-title {
            color: $YELLOW;
            margin-bottom: $SPACING_XXL;
            word-break: break-all;
        }

        &-subtitle {
            font-weight: $BOLD;
            font-size: $TEXT_XL;
            margin-bottom: $SPACING_S;
        }

        &-list {
            list-style-position: inside;
            margin-bottom: $SPACING_XXL;

            &-item {
                display: grid;
                grid-template-columns: 1rem 1fr;
                align-items: center;
                margin: $SPACING_M 0;

                &--less-space {
                    display: grid;
                    grid-template-columns: 1rem 1fr;
                    align-items: center;
                    margin: $SPACING_S 0;

                    & svg {
                        height: 1.5rem;
                        width: 1.5rem;
                    }
                    & path {
                        stroke: $YELLOW;
                        fill: $YELLOW;
                    }
                    & p {
                        margin-left: $SPACING_M;
                        word-break: keep-all;
                    }
                }

                &--input {
                    margin: $SPACING_M 0;
                    list-style-type: none;

                    &-container {
                        display: flex;
                        align-items: center;
                        gap: $SPACING_S;
                    }
                }

                & svg {
                    height: 1.5rem;
                    width: 1.5rem;
                }
                & path {
                    stroke: $YELLOW;
                    fill: $YELLOW;
                }
                & p {
                    margin-left: $SPACING_M;
                }
            }
        }

        &-beschrijving {
            word-break: keep-all;
            margin-bottom: $SPACING_XXL;
        }
    }

    &-form {
        position: relative;
        display: flex;
        justify-content: center;

        @media (max-width: $SCREEN_SIZE_M) {
            margin-top: $SPACING_4XL;
        }

        &-title {
            margin-bottom: $SPACING_S;
        }

        & form {
            align-self: flex-start;
            width: 100%;
            height: auto;
            margin: 0 $SPACING_3XL;
            position: sticky;
            top: $SPACING_XXL;

            @media (max-width: $SCREEN_SIZE_L) {
                margin: 0 $SPACING_L;
            }
            @media (max-width: $SCREEN_SIZE_XS) {
                margin: 0;
            }
        }

        &-section {
            display: flex;
            flex-direction: column;
            margin: $SPACING_M 0;
            width: 100%;

            &-text {
                margin-bottom: $SPACING_XXS;
                display: flex;
                justify-content: space-between;
            }

            & input {
                border: none;
                background-color: $LIGHT_GREY;
                outline: none;
                padding: $SPACING_M;
                font-size: $TEXT_L;
            }

            & textarea {
                min-height: 100px;
                border: none;
                background-color: $LIGHT_GREY;
                resize: none;
                outline: none;
                padding: $SPACING_S;
                font-family: inherit;
                font-size: $TEXT_L;
            }
        }

        &-buttons {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: $SPACING_M 0;
        }

        &-input-file {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            & p {
                margin-bottom: $SPACING_XXS;
            }
            & input {
                position: absolute;
                opacity: 0;
                width: 0.1px;
                height: 0.1px;
            }

            & label {
                display: block;
                background-color: $LIGHT_GREY;
                padding: $SPACING_M;
                border-radius: $SPACING_XXL;
                cursor: pointer;
            }
        }

        &-selected-file {
            display: flex;

            &--icon {
                font-size: $TEXT_3XL;
                cursor: pointer;
            }
            & p {
                width: 50%;
                word-break: break-all;
            }
        }

        &-error {
            color: red;
        }
    }

    &-video {
        &-container {
            margin: $SPACING_5XL 0;
        }
    }

    &-menu {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        @media (max-width: $SCREEN_SIZE_M) {
            grid-template-columns: 1fr;
        }

        &-container {
            margin: $SPACING_5XL 0;
        }

        &-terug {
            padding: $SPACING_M $SPACING_4XL $SPACING_M 0;
            border-right: 1px solid $LIGHT_GREY;
            display: flex;
            justify-content: center;

            @media (max-width: $SCREEN_SIZE_L) {
                padding-right: $SPACING_XL;
            }
            @media (max-width: $SCREEN_SIZE_M) {
                padding: 0 0 $SPACING_XXL 0;
                border-right: none;
                border-bottom: 1px solid $LIGHT_GREY;
            }

            &-content {
                max-width: 60%;
                display: flex;
                align-items: center;
                color: #000;
                text-decoration: none;

                @media (max-width: $SCREEN_SIZE_M) {
                    max-width: 90%;
                }

                & svg {
                    width: 4rem;
                    height: 4rem;
                    margin-left: $SPACING_M;

                    @media (min-width: $SCREEN_SIZE_S) and (max-width: $SCREEN_SIZE_M) {
                        width: 3rem;
                        height: 3rem;
                    }
                }
            }
        }

        &-next {
            padding: $SPACING_M 0 $SPACING_M $SPACING_4XL;
            border-left: 1px solid $LIGHT_GREY;
            display: flex;
            justify-content: center;

            @media (max-width: $SCREEN_SIZE_L) {
                padding-left: $SPACING_XL;
            }
            @media (max-width: $SCREEN_SIZE_M) {
                padding: $SPACING_XXL 0 0 0;
                border-left: none;
                border-top: 1px solid $LIGHT_GREY;
            }

            &-content {
                position: relative;
                background-color: $DARK_GREY;
                color: $LIGHT_GREY;
                width: 75%;
                padding: $SPACING_L;
                word-break: break-all;

                @media (max-width: $SCREEN_SIZE_S) {
                    width: 90%;
                }

                & a {
                    color: $LIGHT_GREY;
                    text-decoration: none;
                }
            }

            &-title {
                color: $YELLOW;
                margin-bottom: $SPACING_XL;
            }

            &-subtitle {
                font-weight: $MEDIUM;
                font-size: $TEXT_L;
            }
        }
    }
}
