@import "../Styles.scss";

.security {
    &-banner {
        position: relative;
        display: grid;
        grid-template-columns: 8fr 4fr;
        width: 100%;
        height: 100%;

        @media(max-width: $SCREEN_SIZE_L) {
            grid-template-columns: 7fr 5fr;
        }
        @media(max-width: $SCREEN_SIZE_M) {
            grid-template-columns: unset;
        }

        &-image {
            position: relative;
            width: 110%;
            height: auto;
            display: block;
            padding-bottom: 75%;

            @media(max-width: $SCREEN_SIZE_M) {
                width: 100%;
            }
            @media (min-width: $SCREEN_SIZE_M) and (max-width: $SCREEN_SIZE_L) {
                padding-bottom: 100%;
            }

            & img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                top: 0;
                left: 0;
            }
        }

        &-content {
            background-color: $DARK_GREY;
            transform: translateY(25px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: $SPACING_L $SPACING_L;
            -webkit-box-shadow: 0px 0px 8px 0px #000000;
            box-shadow: 0px 0px 8px 0px #000000;

            @media(max-width: $SCREEN_SIZE_M) {
                transform: translateY(0px);
                -webkit-box-shadow: 0px 0px 0px 0px #000000;
                box-shadow: 0px 0px 0px 0px #000000;
            }

            &-text {
                &-title {
                    color: $YELLOW;
                }
                &-text {
                    color: $LIGHT_GREY;
                    margin-top: $SPACING_L;

                    &--bold {
                        font-weight: $BOLD;
                    }
                }
                & ul {
                    list-style-position: inside;
                    & p {
                        display: inline-block;
                    }
                }
            }

            &-footer {
                display: flex;
                align-items: center;

                @media(max-width: $SCREEN_SIZE_M) {
                    margin-top: $SPACING_L;
                }

                &-button {
                    margin-right: $SPACING_L;
                }
                &-link {
                    color: $YELLOW;
                    text-underline-offset: 2px;
                }
            }
        }

    }

    &-line {
        width: 100%;
        height: 2px;
        background-color: $LIGHT_GREY;
        margin: $SPACING_5XL 0;

        @media (max-width: $SCREEN_SIZE_M) {
            margin: $SPACING_4XL 0;
        }
    }

    &-inhoud {
        display: grid;
        grid-template-columns: 1fr 1fr;

        @media (max-width: $SCREEN_SIZE_M) {
            grid-template-columns: 1fr;
        }

        &-image {
            & img {
                width: 100%;
            }
        }

        &-content {
            padding: $SPACING_L;

            @media (max-width: $SCREEN_SIZE_M) {
                padding: $SPACING_L 0;
            }

            &-text {
                color: $DARK_GREY;
                margin-top: $SPACING_M;
            }

            & ul {
                margin-top: $SPACING_L;
                list-style-position: inside;

                & p {
                    display: inline-block;
                }
            }
        }
    }

    &-bedrijven {
        &-overzicht {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            align-items: center;
            margin: $SPACING_XL 0;

            @media (min-width: $SCREEN_SIZE_XS) {
                grid-template-columns: repeat(2, 1fr);
            }
            @media (min-width: $SCREEN_SIZE_M) {
                grid-template-columns: repeat(4, 1fr);
            }
        }
        &-image {
            margin: $SPACING_XL $SPACING_3XL;

            & img {
                max-width: 100%;
            }
        }
    }
}
