@import "../Styles.scss";

.nieuwsberichten {
    display: grid;
    grid-template-columns: 9fr 3fr;
    column-gap: $SPACING_L;
    align-items: flex-start;

    &-titel {
        grid-column: 1/3;
    }

    &-artikelen {
        max-width: 700px;

        &-item {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: $SPACING_M;
            margin: $SPACING_L 0;

            &-banner {
                width: 170px;
                height: 170px;
                object-fit: cover;
            }

            &-inhoud {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                padding: $SPACING_XXS 0;
            }
            &-titel {
                font-size: $TEXT_XXL;
                text-decoration: none;
                font-weight: $BOLD;
                color: $BLACK;
            }
            &-datum {
                font-weight: $LIGHT;
                font-size: $TEXT_S;
            }
            &-paragraaf {
                margin-top: $SPACING_M;
                font-size: $TEXT_M;
                font-weight: $MEDIUM;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
            }
            &-link {
                margin-top: $SPACING_XS;
                text-decoration: underline;
                font-size: $TEXT_M;
                font-weight: $MEDIUM;
                color: $BLACK;
            }
        }

        &-pagination {
            display: flex;
            justify-content: center;
            margin: $SPACING_XL 0;
        }
    }

    &-socials {
        position: sticky;
        top: $SPACING_L;
        border-left: 1px solid $LIGHT_GREY;
        padding: 0 0 $SPACING_4XL $SPACING_M;
        display: flex;
        flex-direction: column-reverse;

        &-titel {
            font-size: $TEXT_XXL;
            font-weight: $MEDIUM;
            margin-bottom: $SPACING_XS;
        }

        &-items {
            display: flex;
            flex-direction: column;
            gap: $SPACING_XS;
        }

        &-item {
            display: flex;
            align-items: center;
            gap: $SPACING_S;
            margin: $SPACING_3XS 0;

            &-logo {
                height: 25px;
                width: 25px;
            }
            &-link {
                color: $BLACK;
                text-decoration: none;
            }
        }

        @media (max-width: $SCREEN_SIZE_S) {
            border-left: unset;
            padding-left: 0;
            flex-direction: column;
        }
    }

    @media (max-width: $SCREEN_SIZE_M) {
        grid-template-columns: 7fr 5fr;
    }
    @media (max-width: $SCREEN_SIZE_S) {
        grid-template-columns: 1fr;
        &-titel {
            grid-column: unset;
        }
        &-socials {
            &-titel {
                margin-bottom: $SPACING_M;
            }
            &-items {
                flex-direction: row;
                align-items: center;
                gap: $SPACING_3XL;
            }
        }

        &-artikelen {
            &-item {
                &-banner {
                    width: 100px;
                    height: 100px;
                }

                &-inhoud {
                    padding: 0;
                }
                &-titel {
                    font-size: $TEXT_XL;
                    font-weight: $MEDIUM;
                    word-break: break-word;
                }
                &-datum {
                    font-size: $TEXT_XS;
                }
                &-paragraaf {
                    display: none;
                }
                &-link {
                    display: none;
                }
            }
        }
    }
}
