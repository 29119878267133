@import "../../Styles.scss";

.keep-updated {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: $SPACING_3XL 0;

    &-title {
        font-size: $TEXT_4XL;
        position: relative;
        text-align: center;
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        gap: 0.5rem;
        justify-content: center;

        @media (max-width: $SCREEN_SIZE_S) {
            font-size: $TEXT_3XL;
        }

        &--underline {
            display: inline-block;
            line-height: $TEXT_XL;
            border-bottom: 10px solid $YELLOW;

            @media (max-width: $SCREEN_SIZE_S) {
                line-height: $TEXT_L;
                border-bottom: 0.5rem solid $YELLOW;
            }
        }
    }

    &-subtitle {
        display: flex;
        align-items: flex-end;
        gap: 0.5rem;
        flex-wrap: wrap;
        &--underline {
            display: inline-block;
            line-height: $TEXT_L;
            border-bottom: 7.5px solid $YELLOW;

            @media (max-width: $SCREEN_SIZE_S) {
                line-height: $TEXT_L;
                border-bottom: 0.5rem solid $YELLOW;
            }
        }
    }

    &-input {
        flex-grow: 1;
        width: 50%;
        position: relative;
        display: flex;
        align-items: center;
        border: none;
        background-color: $LIGHT_GREY;
        border-radius: $SPACING_L;
        padding: $SPACING_XXS;
        margin: $SPACING_S $SPACING_M $SPACING_M $SPACING_M;
        transition: 0.2s;

        @media (max-width: $SCREEN_SIZE_M) {
            width: 75%;
        }

        @media (max-width: $SCREEN_SIZE_S) {
            width: 100%;
        }

        &--error {
            border: 2px solid red;
        }
        &--success {
            animation: fill .4s ease-in-out forwards;
        }

        & input {
            flex-grow: 1;
            height: 100%;
            width: 100%;
            padding: $SPACING_S;
            border: none;
            background-color: transparent;
            outline: none;
            font-size: $TEXT_L;
        }

        &--icon {
            padding: 0 $SPACING_XS;
            height: 1.5rem;
            width: 1.5rem;
            cursor: pointer;
        }
    }

    &-warning {
        color: red;
        margin-top: $SPACING_M;
    }
}
@keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px $GREEN;
    }
  }
