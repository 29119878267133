@import "../../Styles.scss";

.vacatures-link {
    background-color: $LIGHT_GREY;
    padding: $SPACING_4XL $SPACING_3XL;
    margin: $SPACING_4XL 0;

    @media (max-width: $SCREEN_SIZE_S) {
        padding: $SPACING_XL $SPACING_M;
    }

    &-question-mark {
        font-size: 15rem;
        color: $DARK_GREY;
        margin-left: $SPACING_4XL;

        @media (max-width: $SCREEN_SIZE_S) {
            margin-left: 0;
        }
    }

    &-title {
        font-size: $TEXT_4XL;
        position: relative;
        margin: $SPACING_3XL 0 $SPACING_L;
        display: flex;
        align-items: flex-end;
        gap: 0.5rem;
        flex-wrap: wrap;

        @media (max-width: $SCREEN_SIZE_S) {
            font-size: $TEXT_3XL;
        }

        &--underline {
            display: inline-block;
            line-height: $TEXT_XL;
            border-bottom: 10px solid $YELLOW;

            @media (max-width: $SCREEN_SIZE_S) {
                line-height: $TEXT_L;
                border-bottom: 0.5rem solid $YELLOW;
            }
        }
    }

    &-text {
        color: $DARK_GREY;
        margin-bottom: $SPACING_S;
        max-width: 60%;
        font-size: $TEXT_L;

        @media (max-width: $SCREEN_SIZE_L) {
            max-width: 80%;
        }
        @media (max-width: $SCREEN_SIZE_M) {
            max-width: 100%;
        }
    }

    &-button {
        margin-top: $SPACING_L;
    }
}
