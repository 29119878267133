@import "../../Styles.scss";

.loading {
  svg {
    animation: 0.5s loadingAnimation infinite linear;
    height: 1.5rem;
    width: 1.5rem;
  }
}

@keyframes loadingAnimation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}