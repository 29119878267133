@import "../Styles.scss";

.over-ons {
    &-banner {
        position: relative;
        display: grid;
        grid-template-columns: 8fr 4fr;
        width: 100%;
        height: 100%;

        @media(max-width: $SCREEN_SIZE_L) {
            grid-template-columns: 7fr 5fr;
        }
        @media(max-width: $SCREEN_SIZE_M) {
            grid-template-columns: unset;
        }

        &-image {
            position: relative;
            width: 110%;
            height: auto;
            display: block;
            padding-bottom: 75%;

            @media(max-width: $SCREEN_SIZE_M) {
                width: 100%;
            }
            @media (min-width: $SCREEN_SIZE_M) and (max-width: $SCREEN_SIZE_L) {
                padding-bottom: 100%;
            }

            & img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                top: 0;
                left: 0;
            }
        }

        &-content {
            background-color: $DARK_GREY;
            transform: translateY(25px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: $SPACING_L $SPACING_L;
            -webkit-box-shadow: 0px 0px 8px 0px #000000;
            box-shadow: 0px 0px 8px 0px #000000;

            @media(max-width: $SCREEN_SIZE_M) {
                transform: translateY(0px);
                -webkit-box-shadow: 0px 0px 0px 0px #000000;
                box-shadow: 0px 0px 0px 0px #000000;
            }

            &-text {
                &-title {
                    color: $YELLOW;
                }
                &-text {
                    color: $LIGHT_GREY;
                    margin-top: $SPACING_L;

                    &--bold {
                        font-weight: $BOLD;
                    }
                }
            }

            &-footer {
                display: flex;
                align-items: center;

                @media(max-width: $SCREEN_SIZE_M) {
                    margin-top: $SPACING_L;
                }

                &-button {
                    margin-right: $SPACING_L;
                }
                &-link {
                    color: $YELLOW;
                    text-underline-offset: 2px;
                }
            }
        }
    }

    &-container {
        margin: $SPACING_5XL 0 $SPACING_3XL 0;

        @media (max-width: $SCREEN_SIZE_S) {
            margin: $SPACING_3XL 0 $SPACING_3XL 0;
        }

        &--grid {
            margin: $SPACING_3XL 0;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: $SPACING_3XL;

            @media (max-width: $SCREEN_SIZE_S) {
                grid-template-columns: 1fr;
            }
        }
    }

    &-team {
        &-title {
            font-size: $TEXT_4XL;
            position: relative;
            margin-top: $SPACING_3XL;
            display: flex;
            align-items: flex-end;
            gap: 0.5rem;
            flex-wrap: wrap;

            @media (max-width: $SCREEN_SIZE_S) {
                font-size: $TEXT_3XL;
            }

            &--underline {
                display: inline-block;
                line-height: $TEXT_XL;
                border-bottom: 10px solid $YELLOW;

                @media (max-width: $SCREEN_SIZE_S) {
                    line-height: $TEXT_L;
                    border-bottom: 0.5rem solid $YELLOW;
                }
            }
        }

        &-container {
            margin-top: $SPACING_XL;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            column-gap: $SPACING_L;
            row-gap: $SPACING_L;

            @media (max-width: $SCREEN_SIZE_M) {
                grid-template-columns: repeat(2, 1fr);
            }
        }

        &-card {
            &-image {
                position: relative;

                &::after {
                    content: "";
                    display: block;
                    padding-bottom: 125%;
                }

                & img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &-content {
                padding: $SPACING_S;

                & h2 {
                    @media (max-width: $SCREEN_SIZE_XS) {
                        font-size: $TEXT_XL;
                    }
                }
                & p {
                    @media (max-width: $SCREEN_SIZE_XS) {
                        font-size: $TEXT_S;
                    }
                }
            }

            &-socials {
                display: flex;
                flex-wrap: wrap;
                margin-top: $SPACING_S;

                > * {
                    cursor: pointer;
                    font-size: $TEXT_XXL;
                    margin: 0 $SPACING_XXS;
                    color: $BLACK;

                    &:first-of-type {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    &-sponsoren {
        &-logos {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: $SPACING_M;
            margin: $SPACING_XL 0;
        }
        &-image {
            display: flex;
            align-items: center;
            height: 100%;

            & img {
                width: 100%;
            }
        }
    }

    &-line {
        width: 100%;
        height: 2px;
        background-color: $LIGHT_GREY;
        margin: $SPACING_3XL 0;

        @media (max-width: $SCREEN_SIZE_M) {
            margin: $SPACING_3XL 0;
        }
    }

    &-zakelijk {
        display: flex;
        flex-wrap: wrap;
        gap: $SPACING_4XL;
        margin: $SPACING_L 0;

        &-item {
            & h1 {
                margin-bottom: $SPACING_M;
            }
            & ul {
                list-style-type: none;
            }
            & li {
                margin: $SPACING_XXS 0;
            }
        }
    }
}
