@import "../Styles.scss";

.afmelden {
    &-banner {
        display: grid;
        grid-template-columns: 8fr 4fr;
        width: 100%;
        height: 100%;
        background-color: $DARK_GREY;

        @media(max-width: $SCREEN_SIZE_L) {
            grid-template-columns: 1fr 1fr;
        }
        @media(max-width: $SCREEN_SIZE_M) {
            grid-template-columns: unset;
        }

        &-image {
            position: relative;
            width: 100%;
            height: auto;
            display: block;
            padding-bottom: 75%;

            @media (min-width: $SCREEN_SIZE_M) and (max-width: $SCREEN_SIZE_L) {
                padding-bottom: 100%;
            }

            & img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                top: 0;
                left: 0;
            }
        }

        &-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: $SPACING_L $SPACING_L;

            &-text {
                &-title {
                    color: $YELLOW;
                    font-size: $TEXT_3XL;
                }
                &-text {
                    color: $LIGHT_GREY;
                    margin-top: $SPACING_L;
                    font-size: $TEXT_L;

                    &--bold {
                        font-weight: $BOLD;
                    }
                }
            }

            &-footer {
                display: flex;
                align-items: center;

                @media(max-width: $SCREEN_SIZE_M) {
                    margin-top: $SPACING_L;
                }

                &-button {
                    margin-right: $SPACING_L;
                }
                &-link {
                    color: $YELLOW;
                    text-underline-offset: 2px;
                    font-size: $TEXT_L;
                }
            }
        }
    }

    &-title {
        font-weight: $MEDIUM;
        color: $LIGHT_GREY;

        &--error {
            font-weight: $MEDIUM;
            color: red;
        }
        &--success {
            font-weight: $MEDIUM;
            color: lightgreen;
        }
    }
    &-buttons {
        margin-top: $SPACING_L;

        &--multiple {
            margin-top: $SPACING_L;

            & button:last-of-type {
                margin-left: $SPACING_M;
            }
        }
    }
}
