$SPACING_3XS: 0.125rem;
$SPACING_XXS: 0.375rem;
$SPACING_XS: 0.5rem;
$SPACING_S: 0.625rem;
$SPACING_M: 1rem;
$SPACING_L: 1.875rem;
$SPACING_XL: 2rem;
$SPACING_XXL: 2.5rem;
$SPACING_3XL: 3rem;
$SPACING_4XL: 4rem;
$SPACING_5XL: 7.5rem;