@import "../Styles.scss";

.contact {
    &-banner {
        position: relative;
        width: 100%;
        height: 100%;

        &-image {
            position: relative;

            &::after {
                content: "";
                display: block;
                padding-bottom: 50%;

                @media (max-width: $SCREEN_SIZE_S) {
                    padding-bottom: 100%;
                }
            }

            & img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }
        }

        &-subtitle {
            margin-top: $SPACING_L;
        }
        &-text {
            margin-top: $SPACING_S;
        }

        &-content {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            background-color: $DARK_GREY;
            color: $LIGHT_GREY;
            transform: translateY(-5rem);
            padding: $SPACING_L;
            margin: 0 $SPACING_L;
            -webkit-box-shadow: 0px 0px 8px 0px #000000;
            box-shadow: 0px 0px 8px 0px #000000;

            @media(max-width: $SCREEN_SIZE_S) {
                padding: $SPACING_L $SPACING_M;
                grid-template-columns: 1fr;
                transform: translateY(0);
                margin: 0;
                -webkit-box-shadow: 0px 0px 0px 0px #000000;
                box-shadow: 0px 0px 0px 0px #000000;
            }

            & > div:nth-child(1) {
                padding-right: $SPACING_L;
                border-right: 1px solid $LIGHT_GREY;

                @media (max-width: $SCREEN_SIZE_S) {
                    border-right: none;
                    padding-right: 0;
                    border-bottom: 1px solid $LIGHT_GREY;
                    padding-bottom: $SPACING_L;
                }
            }
            & > div:nth-child(2) {
                padding-left: $SPACING_L;
                border-left: 1px solid $LIGHT_GREY;

                @media (max-width: $SCREEN_SIZE_S) {
                    border-left: none;
                    padding-left: 0;
                    border-top: 1px solid $LIGHT_GREY;
                    padding-top: $SPACING_L;
                }
            }
        }

        &-list {
            list-style-type: none;
            margin-top: $SPACING_M;

            & > li {
                line-height: 2rem;
                font-size: $TEXT_L;
            }

            &-item {
                display: flex;
                align-items: center;

                & > a {
                    margin-left: $SPACING_S;
                    color: $LIGHT_GREY;
                    text-decoration: underline;
                }

                &-icon {
                    height: 1.6rem;
                    width: 1.6rem;
                }
            }
        }
    }

    &-form {
        position: relative;
        display: flex;
        justify-content: center;

        @media (max-width: $SCREEN_SIZE_M) {
            margin-top: $SPACING_4XL;
        }

        &-title {
            margin-bottom: $SPACING_S;

            &--yellow {
                color: $YELLOW;
            }
        }

        & form {
            align-self: flex-start;
            width: 100%;
            height: auto;
            margin: 0 $SPACING_3XL;
            position: sticky;
            top: $SPACING_XXL;

            @media (max-width: $SCREEN_SIZE_L) {
                margin: 0 $SPACING_L;
            }
            @media (max-width: $SCREEN_SIZE_XS) {
                margin: 0;
            }
        }

        &-section {
            display: flex;
            flex-direction: column;
            margin: $SPACING_M 0;
            width: 100%;

            &-text {
                margin-bottom: $SPACING_XXS;
                display: flex;
                justify-content: space-between;
            }

            & input {
                border: none;
                background-color: $LIGHT_GREY;
                outline: none;
                padding: $SPACING_M;
                font-size: $TEXT_L;
            }

            & textarea {
                min-height: 100px;
                border: none;
                background-color: $LIGHT_GREY;
                resize: none;
                outline: none;
                padding: $SPACING_S;
                font-family: inherit;
                font-size: $TEXT_L;
            }
        }

        &-buttons {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: $SPACING_M 0;
        }

        &-input-file {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            & p {
                margin-bottom: $SPACING_XXS;
            }
            & input {
                position: absolute;
                opacity: 0;
                width: 0.1px;
                height: 0.1px;
            }

            & label {
                display: block;
                background-color: $LIGHT_GREY;
                padding: $SPACING_M;
                border-radius: $SPACING_XXL;
                cursor: pointer;
            }
        }

        &-selected-file {
            display: flex;

            &--icon {
                font-size: $TEXT_3XL;
                cursor: pointer;
            }
            & p {
                width: 50%;
                word-break: break-all;
            }
        }

        &-error {
            color: red;
        }
    }
}
