@import "../../Styles.scss";

.keep-updated-news {
    display: flex;
    flex-direction: column;
    margin: $SPACING_3XL 0;

    &-title {
        font-size: $TEXT_XL;
        position: relative;
        flex-wrap: wrap;
        gap: 0.5rem;
        justify-content: center;
        margin-left: $SPACING_M;
    }

    &-text {
        font-size: $TEXT_M;
        font-weight: $MEDIUM;
        position: relative;
        flex-wrap: wrap;
        gap: 0.5rem;
        justify-content: center;
        margin-left: $SPACING_M;
    }

    &-input {
        flex-grow: 1;
        width: calc(100% - $SPACING_XXS*2);
        max-width: 500px;
        position: relative;
        display: flex;
        align-items: center;
        border: none;
        background-color: $LIGHT_GREY;
        border-radius: $SPACING_L;
        padding: $SPACING_XXS;
        margin: 0;
        transition: 0.2s;

        &--error {
            border: 2px solid red;
        }
        &--success {
            animation: fill .4s ease-in-out forwards;
        }

        & input {
            flex-grow: 1;
            height: 100%;
            width: 100%;
            padding: $SPACING_S;
            border: none;
            background-color: transparent;
            outline: none;
            font-size: $TEXT_L;
        }

        &--icon {
            padding: 0 $SPACING_XS;
            height: 1.5rem;
            width: 1.5rem;
            cursor: pointer;
        }
    }

    &-warning {
        color: red;
        margin-left: $SPACING_M;
        margin-top: $SPACING_M;
    }
}
@keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px $GREEN;
    }
  }
